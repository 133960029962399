/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {FAQ} from "@components/seo/googleSearch";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h3: "h3",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(FAQ, {
    faq: [{
      q: "Зачем нужны яркостные маски?",
      a: "Яркостные маски - очень гибкий инструмент, который даёт полный контроль над деталями изображения и оттенками при обработке."
    }, {
      q: "Зачем панель? Я могу строить маски руками.",
      a: "С панелью вы получите более точный результат быстрее. А некоторые маски, как, например, маски по цветовому диапазону, вручную построить почти невозможно."
    }, {
      q: "Нужно ли платить за обновления?",
      a: "Нет, вы покупаете ARCPanel один раз, все будущие обновления бесплатно. Навсегда."
    }]
  }), "\n", React.createElement(_components.h1, {
    id: "faq",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#faq",
    "aria-label": "faq permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "FAQ"), "\n", React.createElement(_components.h3, {
    id: "мне-правда-нужны-яркостные-маски",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BC%D0%BD%D0%B5-%D0%BF%D1%80%D0%B0%D0%B2%D0%B4%D0%B0-%D0%BD%D1%83%D0%B6%D0%BD%D1%8B-%D1%8F%D1%80%D0%BA%D0%BE%D1%81%D1%82%D0%BD%D1%8B%D0%B5-%D0%BC%D0%B0%D1%81%D0%BA%D0%B8",
    "aria-label": "мне правда нужны яркостные маски permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Мне правда нужны яркостные маски?"), "\n", React.createElement(_components.p, null, "Зависит от ваших целей. Если вы хотите быструю однокнопочную обработку – пожалуй, нет. Для этого есть другие плагины."), "\n", React.createElement(_components.p, null, "Если же вам нужен контроль над деталями и нюансами цвета и света в обработке – яркостные маски будут лучшим решением. Именно ими пользуются многие известные пейзажисты при обработке."), "\n", React.createElement(_components.h3, {
    id: "зачем-панель-я-могу-строить-маски-руками",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%B7%D0%B0%D1%87%D0%B5%D0%BC-%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D1%8C-%D1%8F-%D0%BC%D0%BE%D0%B3%D1%83-%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%BC%D0%B0%D1%81%D0%BA%D0%B8-%D1%80%D1%83%D0%BA%D0%B0%D0%BC%D0%B8",
    "aria-label": "зачем панель я могу строить маски руками permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Зачем панель? Я могу строить маски руками."), "\n", React.createElement(_components.p, null, "Конечно можете, я именно так и делал. Но времена меняются. Я начал снимать более сложные сцены, сводил больше кадров. Я также начал печатать свои работы и стал более внимателен к деталям и качеству. Аккуратные маски по цветовому диапазону вручную не строятся вообще. Ещё один важный фактор это скорость. ARCPanel намного быстрее ручных масок, особенно на больших кадрах в 50+ мегапикселей."), "\n", React.createElement(_components.h3, {
    id: "за-обновление-тоже-надо-платить",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%B7%D0%B0-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%82%D0%BE%D0%B6%D0%B5-%D0%BD%D0%B0%D0%B4%D0%BE-%D0%BF%D0%BB%D0%B0%D1%82%D0%B8%D1%82%D1%8C",
    "aria-label": "за обновление тоже надо платить permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "За обновление тоже надо платить?"), "\n", React.createElement(_components.p, null, "Нет, вы покупаете ARCPanel один раз, все будущие обновления бесплатно. Навсегда."), "\n", React.createElement(_components.h3, {
    id: "какая-последняя-версия",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BA%D0%B0%D0%BA%D0%B0%D1%8F-%D0%BF%D0%BE%D1%81%D0%BB%D0%B5%D0%B4%D0%BD%D1%8F%D1%8F-%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%8F",
    "aria-label": "какая последняя версия permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Какая последняя версия?"), "\n", React.createElement(_components.p, null, "ARCPanel 3.0 сейчас в фазе активной разработки. Вы также пока ещё можете пользоваться ARCPanel 2.2, но она больше активно не поддерживается и новый функционал в неё добавляться не будет."), "\n", React.createElement(_components.h3, {
    id: "вы-не-убедили-почему-именно-arcpanel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%B2%D1%8B-%D0%BD%D0%B5-%D1%83%D0%B1%D0%B5%D0%B4%D0%B8%D0%BB%D0%B8-%D0%BF%D0%BE%D1%87%D0%B5%D0%BC%D1%83-%D0%B8%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE-arcpanel",
    "aria-label": "вы не убедили почему именно arcpanel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Вы не убедили, почему именно ARCPanel?"), "\n", React.createElement(_components.p, null, "Она быстрая и удобная, и бесплатная."), "\n", React.createElement(_components.h3, {
    id: "для-чего-я-могу-использовать-arcpanel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%B4%D0%BB%D1%8F-%D1%87%D0%B5%D0%B3%D0%BE-%D1%8F-%D0%BC%D0%BE%D0%B3%D1%83-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-arcpanel",
    "aria-label": "для чего я могу использовать arcpanel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Для чего я могу использовать ARCPanel?"), "\n", React.createElement(_components.p, null, "Яркостные маски помогают сводить и обрабатывать пейзажи, делать цветовую коррекцию в стрит и портретной фотографии, а в ARCRetouch есть Частотное Разделение и Додж/Бёрн, которые используют в любых жанрах фотографии."), "\n", React.createElement(_components.h3, {
    id: "какие-версии-adobe-photoshop-поддерживаются",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BA%D0%B0%D0%BA%D0%B8%D0%B5-%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D0%B8-adobe-photoshop-%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%B8%D0%B2%D0%B0%D1%8E%D1%82%D1%81%D1%8F",
    "aria-label": "какие версии adobe photoshop поддерживаются permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Какие версии Adobe Photoshop поддерживаются?"), "\n", React.createElement(_components.p, null, "ARCPanel 2.2 отлично работает на Adobe Photoshop Creative Cloud 2015.5 и выше. К сожалению, более старые версии Photoshop не поддерживаются. Панель также будет работать на вручную установелных версиях Adobe Photoshop, но я не могу обещать 100% совместимость. Также я не оказываю поддержку на пиратских копиях Adobe Photoshop."), "\n", React.createElement(_components.p, null, "ARCPanel 3.0 работает на Adobe Photoshop CC 2022 и выше."), "\n", React.createElement(_components.h3, {
    id: "как-работает-активация-arcpanel-22",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BA%D0%B0%D0%BA-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0%D0%B5%D1%82-%D0%B0%D0%BA%D1%82%D0%B8%D0%B2%D0%B0%D1%86%D0%B8%D1%8F-arcpanel-22",
    "aria-label": "как работает активация arcpanel 22 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Как работает активация ARCPanel 2.2?"), "\n", React.createElement(_components.p, null, "Когда вы вводите ключ активации в панели она привязывается к лицензии вашего Adobe Photoshop. Везде, где будет работать ваш лицензионный Photoshop, панель будет работать. На данный момент Adobe разрешает только 2 параллельные установки Photoshop, это значит что на второй копии вы можете также установить панель и активировать своим ключом активации."), "\n", React.createElement(_components.h3, {
    id: "как-работает-активация-arcpanel-30",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BA%D0%B0%D0%BA-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0%D0%B5%D1%82-%D0%B0%D0%BA%D1%82%D0%B8%D0%B2%D0%B0%D1%86%D0%B8%D1%8F-arcpanel-30",
    "aria-label": "как работает активация arcpanel 30 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Как работает активация ARCPanel 3.0?"), "\n", React.createElement(_components.p, null, "Для ARCPanel 3.0 вы получите при покупке 3 лицензионных ключа. Это позволяет запускать 3 копии панели одновременно на 3х компьютерах. Вы также можете в любой момент деактивировать ключ на одном компьютере и активировать на другом. Не забудьте сделать это если больше не хотите использовать панель на текущем компьютере."), "\n", React.createElement(_components.h3, {
    id: "как-насчёт-пиратских-копий-photoshop",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%87%D1%91%D1%82-%D0%BF%D0%B8%D1%80%D0%B0%D1%82%D1%81%D0%BA%D0%B8%D1%85-%D0%BA%D0%BE%D0%BF%D0%B8%D0%B9-photoshop",
    "aria-label": "как насчёт пиратских копий photoshop permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Как насчёт пиратских копий Photoshop?"), "\n", React.createElement(_components.p, null, "ARCPanel 2.2 будет работать и на пиратской копии. Но, я не могу 100% гарантировать полноценную работу панели на пиратских копиях. Вы также не сможете использовать свою лицензию панели на нескольких компьютерах, это возможно только на лицензионной версии Adobe Photoshop."), "\n", React.createElement(_components.h3, {
    id: "что-если-я-хочу-переустановить-photoshop",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D1%87%D1%82%D0%BE-%D0%B5%D1%81%D0%BB%D0%B8-%D1%8F-%D1%85%D0%BE%D1%87%D1%83-%D0%BF%D0%B5%D1%80%D0%B5%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%B8%D1%82%D1%8C-photoshop",
    "aria-label": "что если я хочу переустановить photoshop permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Что если я хочу переустановить Photoshop?"), "\n", React.createElement(_components.p, null, "Деактивируйте ваш ключ от ARCPanel 3.0 перед тем как удалить Adobe Photoshop и активируйте его снова после установки."), "\n", React.createElement(_components.h3, {
    id: "в-чём-отличие-arcpanel-от-lumenzia-tk-actions-или-adp-pro",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%B2-%D1%87%D1%91%D0%BC-%D0%BE%D1%82%D0%BB%D0%B8%D1%87%D0%B8%D0%B5-arcpanel-%D0%BE%D1%82-lumenzia-tk-actions-%D0%B8%D0%BB%D0%B8-adp-pro",
    "aria-label": "в чём отличие arcpanel от lumenzia tk actions или adp pro permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "В чём отличие ARCPanel от Lumenzia, TK Actions или ADP Pro?"), "\n", React.createElement(_components.p, null, "ARCPanel намного проще в использовании. Это основное отличие. Также,"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Она строит маски также или быстрее"), "\n", React.createElement(_components.li, null, "Она дешевле"), "\n", React.createElement(_components.li, null, "Качество масок не уступает"), "\n", React.createElement(_components.li, null, "Есть уникальные особенности, например аккуратные цветовые маски со свободным диапазоном цветов, честная яркость или цветовое тонирование"), "\n"), "\n", React.createElement(_components.h3, {
    id: "и-всё-же-зачем-ещё-одна-панель",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%B8-%D0%B2%D1%81%D1%91-%D0%B6%D0%B5-%D0%B7%D0%B0%D1%87%D0%B5%D0%BC-%D0%B5%D1%89%D1%91-%D0%BE%D0%B4%D0%BD%D0%B0-%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D1%8C",
    "aria-label": "и всё же зачем ещё одна панель permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "И всё же, зачем ещё одна панель?"), "\n", React.createElement(_components.p, null, "Я строил маски через экшены много лет. Это было медленно и не очень удобно. Ручные маски неудобно обновлять, и в какой-то момент я решил просто купить себе панель. Я знал, что яркостные маски не очень сложная проблема, так что я очень удивился тому, что все существующие панели выглядят как пульт от старого телевизора."), "\n", React.createElement(_components.p, null, "Тогда я и решил сделать что-то более удобное и быстрое в работе."), "\n", React.createElement(_components.h3, {
    id: "у-меня-есть-другой-вопрос",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D1%83-%D0%BC%D0%B5%D0%BD%D1%8F-%D0%B5%D1%81%D1%82%D1%8C-%D0%B4%D1%80%D1%83%D0%B3%D0%BE%D0%B9-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81",
    "aria-label": "у меня есть другой вопрос permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "У меня есть другой вопрос"), "\n", React.createElement(_components.p, null, "Со мной всегда можно связаться по почте anton.averin.photo@gmail.com или в Facebook Messenger."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
